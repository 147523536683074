.sign {
    background: var(--secondarycolor);
    padding: 40px 0;
}
.sign h2 {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}
.tarjetas {
    row-gap: 10px;
    column-gap: 30px;
    justify-content: center;
    margin: 30px 0;
    border: solid 4px var(--secondarycolor);
    border-radius: 15px;
    padding: 25px 40px 40px;
}
.tarjetas img {
    width: 70px;
    height: auto;
    object-fit: contain;
    max-height: 60px;
}
.tarjetas h3 {
    color: rgb(77, 77, 77);
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    text-transform: uppercase;
}
.card {
  background-color: #dfdfdf;
  display: flex;
  flex-direction: row;
  border-radius: 10px 10px 150px 10px;
  padding: 40px 30px;
  justify-content: space-between;
  align-items: center;
}
.card img {
  height: 310px;
  width: 100%;
  object-fit: contain;
  flex: 0 0 400px;
  max-width: 400px;
  margin: -30px;
}

.card h2 {
  text-transform: uppercase;
  color: var(--maincolor);
  margin-bottom: 1rem;
}
.card ul {
  list-style: none;
  padding: 0;
}

.price {
  padding: 40px 0px 25px;
  background: #f2f9ff;
  border-radius: 15px;
  min-width: 260px;
  margin-right: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: center;
}
.price h1 {
  font-size: 3.4rem;
  color: var(--maincolor);
  text-align: right;
  margin: 0;
  padding: 0 35px;
  font-weight: 700;
  margin-top: 10px;
}
.price h3 {
  font-weight: 600;
  font-size: 1.7rem;
  padding: 0 35px;
  margin: -10px 0 0 0;
  text-align: right;
  color: var(--maincolor);
}
.price h4 {
  text-align: end;
  margin: 5px 35px 0;
  padding: 3px 10px;
  font-size: 16px;
  background: #0060a9;
  color: #fff;
  width: fit-content;
  align-self: end;
}
.price .boton {
  margin-top: 20px;
  width: 100%;
}
.price span:not(.blobDesc span) {
  padding: 0 35px;
  text-align: right;
  color: var(--maincolor);
  margin-bottom: -6px;
}

.price span strong {
  font-size: 1.2rem;
  color: var(--maincolor);
}
.info {
  width: 40%;
}
.blobDesc {
  position: absolute;
  top: -100px;
  background: #ef5f5f;
  z-index: 1;
  right: -90px;
  text-align: center;
  color: #fff;
  max-width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 100px;
}
.blobDesc * {
  margin: 0 !important;
}
.blobDesc span {
  line-height: 1;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.blobDesc h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}
.antes strong {
  text-decoration: dashed;
  position: relative;
}
.antes strong::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1.5px;
  top: 50%;
  background: var(--maincolor);
}

/* Media */

@media (max-width: 850px) {
  .card {
    flex-wrap: wrap;
    border-radius: 10px 10px 100px 10px;
    justify-content: center;
    max-width: 500px;
    align-self: center;
  }
  .card img {
    height: 220px;
    flex: 0 0 auto;
    max-width: 400px;
    margin: -20px 0 -10px;
  }
  .price {
    margin: 20px 0 10px 0;
    width: 100%;
    padding: 20px 0;
  }
  .price span:not(.blobDesc span) {
    margin-bottom: -10px;
    font-size: 0.9rem;
  }
  .price span strong {
    font-size: 1.1rem;
  }
  .price h1 {
    margin-top: 15px;
  }
  .price h3,
  .price h1,
  .price span:not(.blobDesc span) {
    padding: 0 30px;
  }
  .blobDesc {
    top: -600px;
    right: -50px;
  }
  .info {
    width: 100%;
  }
}

.control .boton {
    margin-top: 20px;
}
.control h1 {
    color: var(--maincolor);
}
.control img {
    width: 40%;
    min-width: 350px;
}
/* Media */

@media (max-width: 850px) {
    .control > div {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .control img {
        width: 100%;
        min-width: 250px;
        height: 360px;
        object-fit: contain;
    }
}
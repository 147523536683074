.hero,
.heroCart {
  background: linear-gradient(0deg, #058afc 8.89%, #0152ab 94.74%);
  position: relative;
  min-height: 550px;
}

.hero::before {
  content: "";
  background: url(../assets/bckhero.svg) no-repeat center 80%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.hero img {
  margin: -150px 0 -150px;
  object-fit: contain;
  position: absolute;
  right: -50px;
  max-width: 700px;
  width: 65%;
}

.hero h2 {
  margin-top: 20px;
}

/* Media */

@media (max-width: 850px) {
  .hero {
    height: 100%;
    max-height: 620px;
    display: flex;
    align-items: flex-end;
  }

  .hero img {
    top: -10px;
    margin: 0 !important;
    right: -75px;
    width: 280px;
    max-width: 330px;
  }

  .hero::before {
    background-position: 50% 30%;
  }

  .hero h2 {
    width: 60%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;700;800&display=swap");

:root {
  --maincolor: #0060a9;
  --secondarycolor: #ff822e;
  --lightcolor: #f4f4f5;
  --graycolor: #8a8a8a;
  --darkcolor: #30302f;
}

* {
  scroll-behavior: smooth;
  scroll-margin-top: 0px;
}

::marker {
  font-size: 30px;
  line-height: 30%;
  color: var(--secondarycolor);
  height: 10px;
}

/* Scroll */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

body {
  background: var(--lightcolor);
}

html,
body,
#root,
.App {
  position: relative;
}

html {
  font-family: "Work Sans", sans-serif;
  line-height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 100% 16px;
}

@media (max-width: 850px) {
  html {
    font-size: 17px;
  }
}

@media (min-width: 850px) and (max-width: 1200px) {
  html {
    font-size: 15px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

li {
  font-size: 0.9rem;
  line-height: 1.2;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Work Sans", sans-serif;
}

.App {
  overflow-x: hidden;
}

/* Titles and Paragraph */

strong {
  font-weight: 800;
}

.black {
  color: var(--darkcolor);
}

.white {
  color: #fff !important;
  opacity: 1;
}

.textCenter {
  text-align: center;
}

h1 {
  font-size: clamp(3.2rem, 8vw, 4.1rem);
  font-weight: 800;
  line-height: 90%;
  color: var(--darkcolor);
  margin: 0 0 2rem;
  text-transform: uppercase;
}

h1 span {
  font-weight: 800;
  color: var(--maincolor);
}

h2 {
  font-size: clamp(2rem, 7vw, 2.2rem);
  font-weight: 400;
  line-height: 120%;
  color: var(--darkcolor);
  margin: 0 0 2rem;
}

h3 {
  font-size: clamp(1.7rem, 7vw, 2.1rem);
  font-weight: 600;
  line-height: 120%;
  color: #fff;
  margin: 0 0 1rem;
}

h4 {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  font-weight: 600;
  line-height: 120%;
  color: #6c6c6c;
  margin: 0 0 0.3rem;
}

p {
  font-size: clamp(1.1rem, 4vw, 1.4rem);
  font-weight: 400;
  line-height: 1.4;
  color: var(--darkcolor);
  margin: 0 0 1rem;
  opacity: 0.7;
}

h5 {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 100%;
  color: var(--lightcolor);
  background-color: var(--secondarycolor);
  margin: 0 0 0.6rem 0;
  padding: 3px 15px;
  width: fit-content;
  border-radius: 5px;
}

h5.primary {
  background: var(--maincolor);
  color: #fff;
}

h5.secondary {
  background: var(--secondarycolor);
  color: #fff;
}

h5.tertiary {
  background: var(--darkcolor);
  color: #fff;
}

@media (max-width: 850px) {
  h1 {
    font-size: max(6vw, 2.2rem);
    line-height: 1.1;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: max(4vw, 1.7rem);
    margin-bottom: 1rem;
  }

  h3 {
    font-size: clamp(1.4rem, 6vw, 1.5rem);
  }

  h5 {
    font-size: 0.9rem;
    padding: 5px 17px;
  }

  p {
    font-size: clamp(0.9rem, 4vw, 1rem);
  }
}

/* /Titles and Paragraph */

/* Relative */

.relative {
  position: relative;
}

/* /Relative */

/* Container */
.container-lg {
  width: stretch;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container-lg {
    width: 750px;
  }
}

@media (min-width: 850px) {
  .container-lg {
    width: 840px;
  }
}

@media (min-width: 910px) {
  .container-lg {
    width: 890px;
  }
}

@media (min-width: 992px) {
  .container-lg {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container-lg {
    width: 1170px;
  }
}

@media (min-width: 1300px) {
  .container-lg {
    width: 1280px;
  }
}

/* /Container */

/* Sections */
.minsec {
  padding: 2rem 0;
}
.section {
  padding: 5rem 0;
}

.noPadBot {
  padding-bottom: 0 !important;
}

.noPadTop {
  padding-top: 0 !important;
}

.graySec {
  background-color: #e4e4e4;
}

.fullWidth {
  width: 100%;
}

@media (max-width: 850px) {
  .section {
    padding: 3rem 0;
  }
  .minsec {
    padding: 1.5rem 0;
  }
}

/* /Sections */

/* Flex */

.flex {
  display: flex;
}

.flexCol {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.colReverse {
  flex-direction: column-reverse;
}

.justifySpace {
  justify-content: space-between;
}

.justifyAround {
  justify-content: space-around;
}

.justifyEnd {
  justify-content: flex-end;
}

.vertCenter {
  justify-content: center;
}

.alignCenter {
  align-items: center;
}

.flexWrap {
  flex-wrap: wrap;
}

.selfCenter {
  align-self: center;
}

/* /Flex */

/* Col */
.col35 {
  width: calc(35% - 25px);
}

.col40 {
  width: 40%;
}

.col50 {
  width: calc(50% - 45px);
}

.col60 {
  width: calc(60% - 45px);
}

.col70 {
  width: calc(70% - 45px);
}

.gap {
  row-gap: 40px;
  column-gap: 20px;
}

@media (max-width: 850px) {
  .col35,
  .col40,
  .col50,
  .col60,
  .col70 {
    width: 100%;
  }
}

/* /Col */

/* Boton */
.boton {
  font-size: 1.4rem;
  padding: 12px 20px;
  background: var(--secondarycolor);
  color: #fff;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  width: fit-content;
}

.volver {
  font-size: 0.9rem;
  margin-top: 10px;
  padding: 10px 0 !important;
  background: #fff;
  color: #000;
  display: flex;
  align-items: center;
}
.volver svg {
  margin-right: 10px;
  width: 7px;
}

@media (max-width: 850px) {
  .boton {
    font-size: 1.35rem;
    padding: 10px 24px;
  }
  .volver {
    font-size: 0.9rem;
    margin-top: 10px;
  }
}

/* /Boton */

.counterDiv h2 {
    margin-top: 10px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--maincolor);
}

.counter {
    width: 40%;
    min-width: 450px;
    border: solid 4px var(--secondarycolor);
    padding: 20px 40px;
    border-radius: 15px;
}

.counter > div {
    font-size: 3rem;
    color: var(--secondarycolor);
    font-weight: 700;
    line-height: 1;
    text-align: center;
}

.counter > div span {
    font-size: 1rem;
    color: var(--graycolor);
    font-weight: 400;
    text-align: center;
}

/* Media */
@media (max-width: 850px) {
    .counter {
        width: 100%;
        min-width: 100%;
    }
}
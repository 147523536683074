.formSec h2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.6rem;
}
.form {
    margin-top: 30px;
    row-gap: 10px;
}
.form .boton {
    margin-left: auto;
    margin-top: 10px;
    height: fit-content;
}

.form p {
    width: 50%;
    font-size: 1.2rem;
    margin-bottom: 0;
}

.form input, select {
	height: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
    border: solid 3px var(--secondarycolor);
	border-radius: 10px;
	padding: 16px 25px;
	background: #00000014;
	color: #000;
	transition: all .5s;
    height: fit-content;
}
.cardInput > div:first-of-type {
    height: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
    border: solid 3px var(--secondarycolor);
	border-radius: 10px;
	padding: 8px 25px;
	background: #00000014;
	color: #000;
	transition: all .5s;
    height: fit-content;
}
.cardInput path {
    fill: #000;
}
.css-1rhbuit-multiValue {
    background: #ff822e!important;
}
.css-1rhbuit-multiValue > div:first-of-type {
    color: #fff;
}
.css-1rhbuit-multiValue path {
    fill: #fff;
}
.input100 {
    width: 100%;
}
.input75 {
    width: 74.8%;
}
.input50 {
    width: 49.5%;
}
.input25 {
    width: 24.5%;
}
.form textarea {
    max-height: 200px;
    height: 150px;
}

.form input::placeholder, .form textarea::placeholder, div#react-select-3-placeholder {
    color: #000;
}

.radios input {
	cursor: pointer;
	padding: 13px 15px;
}

.radios input:checked, .form input:focus-visible, .form input:focus-visible::placeholder {
	color: #fff;
    background-color: var(--secondarycolor);
}

.radios input:before {
	content: attr(label);
	display: inline-block;
	text-align: center;
	width: 100%;
}
.loadBoton {
    opacity: .6;
    pointer-events: none;
}
.PhoneInputCountry {
    display: none;
}
input.PhoneInputInput {
    width: 100%;
}


.errorInput  .PhoneInputInput {
    border-color: #f44337!important;
    background: #f4433721;
    animation: shink .4s both .2s ease-in-out;
}

.error {
    background-color: #f44337;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    height: fit-content;
    margin-top: 10px;
}
.error:empty {
    display: none;
}

.radios input {
    width: auto;
}
@keyframes shink {
    0% {
        transform: translateX(0px);
    }

    30% {
        transform: translateX(-10px);
    }

    70% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0px);
    }
}
/* Media */

@media (max-width: 850px) {
    .formSec h2 {
        font-size: 2rem;
    }
    .form input, .controlForm textarea {
        padding: 15px 15px;
        font-size: .9rem;
    }
    .controlForm input {
        font-size: 16px;
    }
    .radios {
        justify-content: left;
        column-gap: 10px;
        row-gap: 10px;
    }
    .form p {
        width:40%;
        font-size: .9rem;
    }
    .input25:not(.input25.areaCode) {
        width: 49.5%;
    }
    .radioDiv, .cardInput {
        width: 100%;
    }
    .form .boton {
        font-size: 1.1rem;
        width: 100%;
    }

}
.iconSection > div {
    row-gap: 40px;
}

.iconSection svg {
    max-width: 120px;
    max-height: 100px;
    margin-bottom: 20px;
}

.iconSection h4 {
    font-weight: 300;
    color: var(--graycolor);
}

.col {
    width: 25%;
}

@media (max-width: 850px) {
    .iconSection svg {
        max-width: 90px;
        max-height: 70px;
        margin-bottom: 20px;
    }
    .col {
        width: 50%;
    }
}
.cartCheck .card {
  border: 3px solid #9b9b9b;
  background-color: transparent;
  height: 200px;
  height: auto;
  padding: 5px 0;
  border-radius: 10px;
}
.cartCheck .card img {
  width: 100%;
  flex: 0 0 190px;
  max-width: 170px;
  margin: -60px 20px;
}
.cartCheck .card .info {
  margin-left: 20px;
}

.cartCheck .price {
  padding: 18px 0;
}

.cartCheck h2 {
  margin-bottom: 1rem;
}

@media (max-width: 850px) {
  .cartCheck .card {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
  }
  .cartCheck .card img {
    margin: -30px 0;
    flex: 0 0 50px;
    max-width: 50px;
  }
  .imgInfo {
    width: 65%;
  }
  .cartCheck .info h5 {
    font-size: 0.7rem;
    padding: 3px 12px;
  }
  .cartCheck .price {
    margin: 0;
    width: 35%;
    min-width: auto;
  }
  .cartCheck .card .info {
    margin-left: 10px;
  }
  .cartCheck .price h1 {
    font-size: 1.7rem;
  }
  .cartCheck .price h3 {
    font-size: 1.1rem;
  }
  .cartCheck .card h2 {
    font-size: 1rem;
  }
  .cartCheck .price h3,
  .cartCheck .price h1,
  .cartCheck .price span:not(.blobDesc span) {
    padding: 0 10px;
  }
  .cartCheck .price span:not(.blobDesc span) {
    font-size: 0.8rem;
    display: flex;
    align-items: baseline;
    margin-bottom: -20px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
  }
  .cartCheck .price span strong {
    font-size: 0.8rem;
    margin-left: 5px;
    margin-top: -5px;
    margin-bottom: 5px;
    align-self: end;
  }
  .cartCheck h2 {
    margin-bottom: 0.7rem;
  }
}

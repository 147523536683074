.preguntas h1 {
    color: var(--maincolor);
}

.preguntas ul {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 30px;
}

.preguntas li {
    font-size: 1.2rem;
    line-height: 1.2;
    color: #555555;
    margin-bottom: 20px;
}
/* Media */

@media (max-width: 850px) {
    .preguntas li {
        font-size: 1rem;
    }
}
.header {
    display: flex;
    flex-direction: column;
    position: relative;
}

.header img {
    height: 100%;
    max-width: 135px;
    padding: 15px 0;
}

/* Media */

@media (max-width: 850px) {
    .header img {
        max-width: 120px;
        height: auto;
    }
}